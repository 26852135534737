export const validateForm = (input) => {

    var validateFormResponse = {
        EM: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input.email) ? true : false,
        MP: /^7\d{9}$/.test(input.phone1) ? true : false,
    }

    if (validateFormResponse.EM && !validateFormResponse.MP) {
        delete validateFormResponse.MP;
    }

    if (!validateFormResponse.EM && validateFormResponse.MP) {
        delete validateFormResponse.EM;
    }

    applyValidationStyles(validateFormResponse)

    return validateFormResponse
}


export const validateApigeeResponse = (response) => {

    let sendInBlueOptOutResponse = false;
    let leadbyteOptOutEmailResponse = false;
    let leadbyteOptOutPhoneResponse = false;

    if (response.apiResponse && response.apiResponse.email !== "" && response.apiResponse.phone1 !== "") {
        // email and phone are both present
        // validate sendInBlueOptOutResponse, leadbyteOptOutEmailResponse and leadbyteOptOutPhoneResponse
        // if apiResponse.sendInBlueOptOutResponse.processId, then sendInBlueOptOutResponse = true
        if (response.apiResponse.sendInBlueOptOutResponse.processId) {
            sendInBlueOptOutResponse = true;
        }

        // if apiResponse.leadbyteOptOutEmailResponse.code is 1 or -2 then leadbyteOptOutEmailResponse = true
        if (response.apiResponse.leadbyteOptOutEmailResponse === "1 | OK" || response.apiResponse.leadbyteOptOutEmailResponse === "-2 | Lead was detected as being a duplicate.") {
            leadbyteOptOutEmailResponse = true;
        }

        // if apiResponse.leadbyteOptOutPhoneResponse.code is 1 or -2 then leadbyteOptOutPhoneResponse = true
        if (response.apiResponse.leadbyteOptOutPhoneResponse === "1 | OK" || response.apiResponse.leadbyteOptOutPhoneResponse === "-2 | Lead was detected as being a duplicate.") {
            leadbyteOptOutPhoneResponse = true;
        }

    } else if (response.apiResponse && response.apiResponse.email !== "" && response.apiResponse.phone1 === "") {
        // email is present but phone is not
        // validate sendInBlueOptOutResponse and leadbyteOptOutEmailResponse
        // validate leadbyteOptOutPhoneResponse to true
        leadbyteOptOutPhoneResponse = true;

        // if apiResponse.sendInBlueOptOutResponse.processId, then sendInBlueOptOutResponse = true
        if (response.apiResponse.sendInBlueOptOutResponse.processId) {
            sendInBlueOptOutResponse = true;
        }

        // if apiResponse.leadbyteOptOutEmailResponse.code is 1 or -2 then leadbyteOptOutEmailResponse = true
        if (response.apiResponse.leadbyteOptOutEmailResponse === "1 | OK" || response.apiResponse.leadbyteOptOutEmailResponse === "-2 | Lead was detected as being a duplicate.") {
            leadbyteOptOutEmailResponse = true;
        }

    } else if (response.apiResponse && response.apiResponse.email === "" && response.apiResponse.phone1 !== "") {
        // phone is present but email is not
        // validate leadbyteOptOutPhoneResponse only
        // validate sendInBlueOptOutResponse and leadbyteOptOutEmailResponse to true
        leadbyteOptOutEmailResponse = true;
        sendInBlueOptOutResponse = true;

        // if apiResponse.leadbyteOptOutPhoneResponse.code is 1 or -2 then leadbyteOptOutPhoneResponse = true
        if (response.apiResponse.leadbyteOptOutPhoneResponse === "1 | OK" || response.apiResponse.leadbyteOptOutPhoneResponse === "-2 | Lead was detected as being a duplicate.") {
            leadbyteOptOutPhoneResponse = true;
        }

    } else {
        // something went wrong with the API
        console.log("An error has occurred");
    }

    // if all the variables are true, then return true otherwise return false
    if (sendInBlueOptOutResponse && leadbyteOptOutEmailResponse && leadbyteOptOutPhoneResponse) {
        return true;
    } else {
        return false;
    }

}


function applyValidationStyles(input) {
    
    for (const key in input) {
        
        if (input[key]) {
            document.getElementById(key).classList.remove('is-invalid')
            document.getElementById(key).classList.add('is-valid')
        } else {
            document.getElementById(key).classList.remove('is-valid')
            document.getElementById(key).classList.add('is-invalid')
        }
    }

}


export const removeValidation = () => {

    if (document.querySelector("#EM").classList.contains('is-valid')) {
        document.querySelector("#EM").classList.remove('is-valid')
    }
    if (document.querySelector("#EM").classList.contains('is-invalid')) {
        document.querySelector("#EM").classList.remove('is-invalid')
    }
    if (document.querySelector("#MP").classList.contains('is-valid')) {
        document.querySelector("#MP").classList.remove('is-valid')
    }
    if (document.querySelector("#MP").classList.contains('is-invalid')) {
        document.querySelector("#MP").classList.remove('is-invalid')
    }
    document.querySelector("#optOutTextAlert").classList.add('d-none')

}